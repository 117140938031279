import React from "react"
import AccountNavigation from "../AccountNavigation"

function AccountContent({children}) {
  return (
    <>
      <AccountNavigation/>
      <div className="my-account__content">
        {children}
      </div>
    </>
  )
}

export default AccountContent