import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import "./account-navigation.scss"
import { useCartContext } from "../../views/cart/CartContext"

const accountNav = [
  {
    link: "/my-account",
    title: "Dashboard"
  },
  {
    link: "/my-account/orders",
    title: "Orders"
  },
  {
    link: "/my-account/addresses",
    title: "Address"
  },
  {
    link: "/my-account/edit-account",
    title: "Account details"
  },
  {
    link: "/",
    title: "Logout"
  }
]

function AccountNavigation(props) {
  const [accountLinkActive, setAccountLinkActive] = useState("/")
  const {clearCart} = useCartContext()
  useEffect(() => {
    let cUrl =  typeof window !=='undefined'?window.location.pathname:null
    if (cUrl.lastIndexOf("/") === (cUrl.length - 1)) {
      cUrl = cUrl.substring(0, cUrl.lastIndexOf("/"))
    }
    setAccountLinkActive(cUrl)
  }, [])

  return (
    <div className="my-account__nav">
      <ul className="my-account__nav--list">
        {accountNav.map((item) =>
          <li className="my-account__nav--item">
            <Link
              className={(accountLinkActive === item.link) ? "active" : ""}
              to={item.link}
              onClick={() => {
                if (item.title == "Logout") {
                  localStorage.removeItem("auth")
                  sessionStorage.removeItem("isLogged")
                  clearCart()
                }
              }}
              >{item.title}
              </Link>
          </li>
        )}
      </ul>
    </div>
  )
}

export default AccountNavigation