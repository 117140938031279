import React, { useState, useEffect } from "react"
import Layout from "../../components/layout"
import AccountContent from "../../components/AccountContent"
import { Link } from "gatsby"
import "./addresses.scss"
import { gql, useQuery } from "@apollo/client"
import { isUserLoggedIn } from "../../utils/function"

function Addresses(props) {
  const USER_ADDRESS = gql`
  query ($customerId: Int){
    customer(customerId: $customerId) {
      billing {
       address1
            address2
            city
            company
            country
            email
            firstName
            lastName
            state
            postcode
      }
      shipping {
       address1
            address2
            city
            company
            country
            email
            firstName
            lastName
            state
            postcode
      }
    }
  }
  `
//   const data_user1 = typeof window !=="undefined"&&window.localStorage.getItem("auth")
// const data_user=JSON.parse(data_user1)
const auth =isUserLoggedIn()
const customerId= auth!==null?auth.userID:null
  const [userAddress, setUserAddress] = useState([])
  const { error,loading,data, refetch } = useQuery(USER_ADDRESS, {
    variables: { customerId: customerId }
  })
  // if (loading) return 'Loading...'
  // if (error) return `Submition error! ${error.message}`
  useEffect(() => {
    if (auth) {
      refetch()
    }
  }, [auth])

  useEffect(() => {
    if (data) {
      setUserAddress(data)
    }
  }, [data])
  
  return (
    <Layout>
      <div className="section my-account">
        <div className="container pd-row">
          <AccountContent>
            <p>The following addresses will be used on the checkout page by default.</p>
            <div className="billing-address__row">
              <div className="billing-address__column">
                <header className="billing-address__header">
                  <h3> Billing address </h3>
                  <Link to="/my-account/edit-address#billing">Edit</Link>
                </header>
                { loading ? "loading..." :
                  <address>
                  { userAddress.customer ? userAddress?.customer?.billing?.firstName : "can't load data"} 
                  {userAddress?.customer?.billing?.lastName} <br />
                  {userAddress.customer ? userAddress?.customer?.billing?.address1 : "can't load data"} <br />
                  {userAddress.customer ? userAddress?.customer?.billing?.city : "can't load data"} {userAddress?.customer?.billing?.state} {userAddress?.customer?.billing?.postcode}
                </address>}
              </div>
              <div className="billing-address__column">
                <header className="billing-address__header">
                  <h3> Shipping address </h3>
                  <Link to="/my-account/edit-address#shipping">Edit</Link>
                </header>
                { loading ? "loading..." :
                  <address>
                  {userAddress.customer ? userAddress?.customer?.shipping?.firstName : "can't load data"} 
                  {userAddress?.customer?.shipping?.lastName} <br />
                  {userAddress.customer ? userAddress?.customer?.shipping?.address1 : "can't load data"} <br />
                  {userAddress.customer ? userAddress?.customer?.shipping?.city : "can't load data"} {userAddress?.customer?.shipping?.state} {userAddress?.customer?.shipping?.postcode}
                </address> }
              </div>
            </div>
        
          </AccountContent>
        </div>
      </div>
    </Layout>
  )
}

export default Addresses